.AnimatedProgressBar {
	opacity: 1;
	animation-name: growWidth;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

@keyframes growWidth {
	0% {
		width: 0px;
	}
	100% {
		
	}
}