.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

::-webkit-scrollbar {
  background-color: #F5F5F5;
  width: 8px;
  /* for vertical scrollbars */
  height: 8px;
  /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgb(60, 60, 60);
}


.background-wallpaper {
  /* background-image: url(http://www.ecopetit.cat/iconpics/f/29/290104/justin-maller-wallpapers.jpg); */
  /* Used if the image is unavailable */
  height: 100%;
  /* You must set a specified height */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */
  filter: blur(4px);
  -webkit-filter: blur(4px);

}

.everything {
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 0;
  left: 0;
}